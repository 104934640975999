import React from "react"
import { CssBaseline, ThemeProvider } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon"
import { ConfirmProvider } from "material-ui-confirm"
import { ErrorBoundary } from "react-error-boundary"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { RouterProvider } from "react-router-dom"
import ErrorFallback from "components/errors/ErrorFallback"
import BackendProvider from "providers/BackendProvider"
import NotificationProvider from "providers/NotificationProvider"
import router from "router"
import theme from "theme"

const App: React.FC = () => (
  <div>
    <HelmetProvider>
      <Helmet>
        <title>Distillery</title>
        <meta
          name="description"
          content="Distillery - Enabling product and marketing teams to make better decisions with AI-driven personalised data insights"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <BackendProvider>
              <ConfirmProvider
                defaultOptions={{
                  confirmationButtonProps: { autoFocus: true },
                }}
              >
                <NotificationProvider>
                  <RouterProvider router={router} />
                </NotificationProvider>
              </ConfirmProvider>
            </BackendProvider>
          </ErrorBoundary>
        </LocalizationProvider>
      </ThemeProvider>
    </HelmetProvider>
  </div>
)

export default App
