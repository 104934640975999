import React from "react"
import { useLocation, useRouteError } from "react-router-dom"
import ErrorFallback from "components/errors/ErrorFallback"
import NotFound from "./NotFound"

interface ErrorWithStatus extends Error {
  status?: number
}

const RouterError: React.FC = () => {
  const location = useLocation()
  const error = useRouteError() as ErrorWithStatus

  if (error.status === 404) return <NotFound />

  if (error.message.includes("Failed to fetch dynamically imported module")) {
    window.location.href = `${location.pathname}${location.search}`
    window.location.reload()

    return null
  }

  return <ErrorFallback />
}

export default RouterError
