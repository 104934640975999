import React from "react"
import { Box, Typography } from "@mui/material"

const ErrorFallback: React.FC = () => (
  <Box
    sx={{
      textAlign: "center",
      pt: 20,
    }}
  >
    <Typography variant="h4">Oops - an error has occurred!</Typography>
  </Box>
)

export default ErrorFallback
